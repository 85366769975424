var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { align: "left" } }, [
    _vm.title !== ""
      ? _c("div", { staticClass: "text-h5 q-mt-sm q-mb-xs" }, [
          _vm._v(_vm._s(_vm.title)),
        ])
      : _vm._e(),
    _vm.sub_title !== ""
      ? _c("div", { staticClass: "text-caption text-grey" }, [
          _vm._v(_vm._s(_vm.sub_title)),
        ])
      : _vm._e(),
    !_vm.info_cellulare.is_certificato || !_vm.certificazione
      ? _c(
          "div",
          { staticClass: "q-gutter-md" },
          [
            _c("br"),
            _c("q-input", {
              attrs: {
                outlined: "",
                type: "number",
                label: "Numero di cellulare del Cliente",
                disable: !_vm.enable_change_num,
                "reactive-rules": "",
                rules: [
                  function (val) {
                    return (
                      val.length < 11 ||
                      "Il numero di cellulare e` formalmente errato. Non inserire il codice internazionale +039"
                    )
                  },
                ],
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "after",
                    fn: function () {
                      return [
                        _c("q-btn", {
                          staticStyle: { "min-width": "160px" },
                          attrs: {
                            label: _vm.label_invia_otp,
                            color: "primary",
                            size: "lg",
                            disabled: _vm.disattiva_pulsante_invia_otp,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onInviaOtp.apply(null, arguments)
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3403315072
              ),
              model: {
                value: _vm.cellulare,
                callback: function ($$v) {
                  _vm.cellulare = $$v
                },
                expression: "cellulare",
              },
            }),
            _vm.otp_inviato && _vm.certificazione
              ? _c(
                  "div",
                  [
                    _vm.isSocieta
                      ? [
                          _c("q-input", {
                            attrs: {
                              outlined: "",
                              type: "text",
                              label: "Nominativo del Rappresentante Legale",
                              "reactive-rules": "",
                              rules: [
                                function (val) {
                                  return (
                                    val !== "" ||
                                    "Il nominativo del Rappresentante Legale è obbligatorio"
                                  )
                                },
                              ],
                            },
                            model: {
                              value: _vm.rappresentante_legale,
                              callback: function ($$v) {
                                _vm.rappresentante_legale = $$v
                              },
                              expression: "rappresentante_legale",
                            },
                          }),
                          _c("q-checkbox", {
                            attrs: {
                              size: "xs",
                              val: "xs",
                              label:
                                "Dichiaro che il cliente è il Rappresentante Legale della Società " +
                                _vm.NominativoCliente,
                              disable: _vm.disattiva_dichiarazioni,
                            },
                            model: {
                              value: _vm.cliente_rappresentante_legale,
                              callback: function ($$v) {
                                _vm.cliente_rappresentante_legale = $$v
                              },
                              expression: "cliente_rappresentante_legale",
                            },
                          }),
                        ]
                      : _vm._e(),
                    _c("q-checkbox", {
                      attrs: {
                        size: "xs",
                        val: "xs",
                        label: "Di aver identificato direttamente il Cliente",
                        disable: _vm.disattiva_dichiarazioni,
                      },
                      model: {
                        value: _vm.cliente_davanti,
                        callback: function ($$v) {
                          _vm.cliente_davanti = $$v
                        },
                        expression: "cliente_davanti",
                      },
                    }),
                    _c("br"),
                    _c("q-checkbox", {
                      attrs: {
                        size: "xs",
                        val: "xs",
                        label:
                          "Dichiaro che ha ricevuto l'sms OTP sul suo cellulare",
                        disable: _vm.disattiva_dichiarazioni,
                      },
                      model: {
                        value: _vm.cliente_ricevuto_otp,
                        callback: function ($$v) {
                          _vm.cliente_ricevuto_otp = $$v
                        },
                        expression: "cliente_ricevuto_otp",
                      },
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.verifica_is_visible && _vm.otp_inviato
              ? _c("q-input", {
                  attrs: {
                    outlined: "",
                    type: "number",
                    label: "Codice OTP ricevuto",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "after",
                        fn: function () {
                          return [
                            _vm.certificazione
                              ? _c("q-btn", {
                                  staticStyle: { "min-width": "160px" },
                                  attrs: {
                                    label: _vm.label_verifica,
                                    color: "primary",
                                    size: "lg",
                                    disabled:
                                      _vm.disattiva_pulsante_verifica_otp ||
                                      !_vm.cliente_davanti ||
                                      !_vm.cliente_ricevuto_otp ||
                                      !_vm.cliente_rappresentante_legale,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.onVerificaOtp.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            !_vm.certificazione
                              ? _c("q-btn", {
                                  staticStyle: { "min-width": "160px" },
                                  attrs: {
                                    label: _vm.label_verifica,
                                    color: "primary",
                                    size: "lg",
                                    disabled:
                                      _vm.disattiva_pulsante_verifica_otp,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.onVerificaOtp.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1376984801
                  ),
                  model: {
                    value: _vm.codice_otp,
                    callback: function ($$v) {
                      _vm.codice_otp = $$v
                    },
                    expression: "codice_otp",
                  },
                })
              : _vm._e(),
            _vm.verifica_avviata
              ? _c("div", [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.is_otp_valid,
                          expression: "!is_otp_valid",
                        },
                      ],
                      staticClass: "row",
                    },
                    [
                      _c("q-icon", {
                        staticClass: "text-red",
                        staticStyle: {
                          "font-size": "3rem",
                          "margin-right": "6px",
                        },
                        attrs: { name: "warning" },
                      }),
                      _c("div", { staticStyle: { "padding-top": "12px" } }, [
                        _vm._v(
                          "ATTENZIONE!! " +
                            _vm._s(_vm.errore_verifica_cellulare)
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.is_otp_valid,
                          expression: "is_otp_valid",
                        },
                      ],
                      staticClass: "row",
                    },
                    [
                      _c("q-icon", {
                        staticClass: "text-green",
                        staticStyle: {
                          "font-size": "3rem",
                          "margin-right": "6px",
                        },
                        attrs: { name: "mdi-check-outline" },
                      }),
                      _c("div", { staticStyle: { "padding-top": "12px" } }, [
                        _vm._v("Il codice OTP è corretto"),
                      ]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.info_cellulare.is_certificato && _vm.certificazione
      ? _c("div", { staticClass: "q-gutter-md" }, [
          _c(
            "div",
            {
              staticClass:
                "text-center justify-center q-pa-md q-gutter-sm text-h5",
            },
            [_vm._v(" Il numero di cellulare è gia stato certificato. ")]
          ),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.is_loading,
            expression: "is_loading",
          },
        ],
        staticClass:
          "text-center justify-center q-pa-md q-gutter-sm fixed-center",
        staticStyle: { "z-index": "1000" },
      },
      [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }